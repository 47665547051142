import cx from 'classnames';
import type { ComponentPropsWithoutRef } from 'react';

type LoaderSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
type LoaderVariant = 'primary' | 'inherit';

interface LoaderProps extends ComponentPropsWithoutRef<'span'> {
  size?: LoaderSize;
  variant?: LoaderVariant;
}

export function Loader({ className, size = 'md', variant = 'primary', ...rest }: LoaderProps) {
  return (
    <span
      aria-label="Loading…"
      className={cx('loading loading-spinner', classesBySize[size], classesByVariant[variant], className)}
      {...rest}
    ></span>
  );
}

const classesByVariant: Record<LoaderVariant, string> = {
  primary: 'text-brand-blue',
  inherit: 'text-inherit',
};

const classesBySize: Record<LoaderSize, string> = {
  xs: 'loading-xs', // 1rem 16px
  sm: 'loading-sm', // 1.25rem 20px
  md: 'loading-md', // 1.5rem 24px
  lg: 'loading-lg', // 2.5rem 40px
  xl: 'w-14', // 3.5rem 56px
  xxl: 'w-20', // 5rem 80px
};
